import "./Tabs.styles.scss";
import React, { useState } from "react";
import { useViewport } from "utils/hooks";
import DownArrow from "assets/icons/arrow-down-thin.svg";
import { Icon } from "components";

export interface TabItem {
  key: string;
  name: string;
  url?: string | null;
  actionComponent?: React.ReactNode | null;
  mainComponent?: React.ReactNode;
  icon?: any;
}

interface TabsProps {
  items: TabItem[];
  activeTab: TabItem;
  setActiveTab: (tab: TabItem) => void;
  size?: string;
  responsive?: boolean;
  placement?: "left" | "right";
  extraClass?: string;
}

const Tabs: React.FC<TabsProps> = ({
  items,
  activeTab,
  setActiveTab,
  size = "",
  responsive = false,
  placement = "right",
  extraClass,
}) => {
  const { isMobile } = useViewport();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const handleMobileTabClick = (tab: TabItem) => {
    setIsMobileMenuOpen(false);
    setActiveTab(tab);
  };

  return (
    <>
      {isMobile && responsive ? (
        <div className="mobile-tabs-container">
          <div
            tabIndex={0}
            role="button"
            className="mobile-tabs-select"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            onKeyDown={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <b>{activeTab.name}</b>
            <Icon src={DownArrow} />
          </div>
          <div className={`mobile-tabs-menu ${isMobileMenuOpen ? "open" : ""}`}>
            {items?.map((tab, i) => {
              if (!tab) {
                return null;
              }
              return (
                <div
                  tabIndex={i}
                  role="button"
                  key={tab.key}
                  className={`mobile-tab-item ${
                    activeTab.key === tab.key ? "active" : ""
                  } ${size}`}
                  onClick={() => handleMobileTabClick(tab)}
                  onKeyDown={() => handleMobileTabClick(tab)}
                >
                  {tab.name}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className={`tabs-container ${placement} ${
            extraClass ? extraClass : ""
          }`}
        >
          {items?.map((tab, i) => {
            if (!tab) {
              return null;
            }
            return (
              <div
                tabIndex={i}
                role="button"
                key={tab.key}
                className={`tab-item ${
                  activeTab.name === tab.name ? "active" : ""
                } ${size}`}
                onClick={() => setActiveTab(tab)}
                onKeyDown={() => setActiveTab(tab)}
              >
                {tab.name}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Tabs;

import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import { formatInput } from "utils/helpers";
import useFetchGroups from "./useFetchGroups";
import { updateGroupAction } from "store/actions/groups";

const useUpdateGroup = (groupId: string | undefined) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchGroups } = useFetchGroups();
  const { user } = useCurrentUser();

  const submitUpdateGroup = (args: any) => {
    if (!args || !groupId) {
      return null;
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateGroup(group: {
                  uuid: "${groupId}",
                  name: "${formatInput(args.name)}",
                  description: "${formatInput(args.description)}",
                  location: "${formatInput(args.location)}",
                  address: {
                    address1: "${formatInput(args.address1)}",
                    address2: "${formatInput(args.address2)}",
                    city: "${formatInput(args.city)}",
                    state: "${formatInput(args.state?.value)}",
                    country: "${formatInput(args.country?.value)}",
                  }
                }) {
                  uuid
                  name
                  description
                  slug
                  location
                  address {
                    address1
                    address2
                    city
                    state
                    country
                  }
                  status
                  createdAt
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            orgId: user?.activeOrgId || "NA",
            permissionKey: "update_groups",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchGroups();
              toast("Your group has been updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("group_updated_success", {
                user_id: user?.id,
                group_id: groupId,
              });
              return dispatch(updateGroupAction(resData.data.updateGroup));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateGroup,
    isUpdateSuccessful,
  };
};

export default useUpdateGroup;

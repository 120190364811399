import "./TextareaField.styles.scss";
import React from "react";
import { Field } from "types";
import FieldLabel from "../FieldLabel";

interface TextareaFieldProps extends Field {
  id?: string;
  readOnly?: boolean;
  forwardRef?: any;
}

const TextareaField = ({
  label,
  error,
  forwardRef,
  hint,
  required,
  ...props
}: TextareaFieldProps) => {
  return (
    <div className="textarea-field">
      <label className="textarea-field__label">
        <FieldLabel value={label} required={required} />
      </label>
      <textarea
        rows={6}
        aria-label={label}
        className={`textarea-field__field ${error ? "error" : ""}`}
        ref={forwardRef}
        required={required}
        {...props}
      />
      {error && (
        <p className="textarea-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && hint !== "null" && (
        <p className="textarea-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default TextareaField;

import { RootStateOrAny, useSelector } from "react-redux";

const useGetTherapistPracticeInfo = () => {
  const therapistPracticeInfo = useSelector(
    (state: RootStateOrAny) => state.therapists.therapistPracticeInfo
  );

  if (!therapistPracticeInfo) {
    return {
      therapistPracticeInfo: null,
      isTherapistPracticeInfoComplete: false,
    };
  }

  const isTherapistPracticeInfoComplete = (): boolean => {
    return (
      !!therapistPracticeInfo &&
      !!therapistPracticeInfo.practiceName &&
      !!therapistPracticeInfo.treatmentType &&
      !!therapistPracticeInfo.practiceSize &&
      !!therapistPracticeInfo.address1 &&
      !!therapistPracticeInfo.state &&
      !!therapistPracticeInfo.zipcode &&
      !!therapistPracticeInfo.phone
    );
  };

  return {
    therapistPracticeInfo,
    isTherapistPracticeInfoComplete: isTherapistPracticeInfoComplete(),
  };
};

export default useGetTherapistPracticeInfo;

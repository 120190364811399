import { PatientOption, Member } from "../types/types";

const formatSubmitData = (data: any, activeTab: string) => {
  let formattedData;
  if (activeTab === "add") {
    formattedData = data.patients.map((patient: PatientOption) => {
      return {
        email: patient.value.email,
        role: data.role,
      };
    });
  } else if (activeTab === "invite") {
    formattedData = data.addedMembers.map((member: Member) => {
      return {
        email: member.email,
        role: data.role,
      };
    });
  }
  return formattedData;
};

export { formatSubmitData };

import PatientsIcon from "assets/icons/users-medical.svg";
import DashboardIcon from "assets/icons/tachometer-alt-fast.svg";
import SettingsIcon from "assets/icons/cog.svg";

import AssignmentsIcon from "assets/icons/clipboard-list-check.svg";
import SubmissionsIcon from "assets/icons/history.svg";
import SafetyPlan from "assets/icons/lifebuoy.svg";
import TherapistIcon from "assets/icons/id-card-alt.svg";
import LibraryIcon from "assets/icons/books.svg";
import GroupIcon from "assets/icons/users.svg";
import OrgsIcon from "assets/icons/sitemap.svg";
import { useGetTherapistProfile } from "utils/hooks";
import { useTranslation } from "react-i18next";

export const useTherapistNavItems = () => {
  const { isTherapistProfileComplete } = useGetTherapistProfile();
  const { t } = useTranslation(["common"]);

  const therapistNavItems = [
    {
      label: t("page_titles.dashboard_title"),
      icon: DashboardIcon,
      link: "/dashboard",
    },
    {
      label: t("page_titles.patients_title"),
      icon: PatientsIcon,
      link: "/patients",
    },
    {
      label: t("page_titles.groups_title"),
      icon: GroupIcon,
      link: "/groups",
    },
    {
      label: t("page_titles.resources_title"),
      icon: LibraryIcon,
      link: "/resources",
    },
    {
      label: t("page_titles.my_practice_title"),
      icon: OrgsIcon,
      link: "/my-practice",
    },
  ];

  const therapistMobileNavItems = [
    ...therapistNavItems,
    {
      label: t("page_titles.settings_title"),
      icon: SettingsIcon,
      link: "/settings",
      hasAlert: !isTherapistProfileComplete,
    },
  ];

  return {
    therapistNavItems,
    therapistMobileNavItems,
  };
};

export const usePatientNavItems = () => {
  const { t } = useTranslation(["common"]);
  const patientNavItems = [
    {
      label: t("patient_nav_items.assignments"),
      icon: AssignmentsIcon,
      link: "/your-assignments",
    },
    {
      label: t("patient_nav_items.submissions"),
      icon: SubmissionsIcon,
      link: "/your-submissions",
    },
    {
      label: t("patient_nav_items.safety_plan"),
      icon: SafetyPlan,
      link: "/safety-plan",
    },
    {
      label: t("patient_nav_items.therapist"),
      icon: TherapistIcon,
      link: "/your-therapist",
    },
    {
      label: t("patient_nav_items.resources"),
      icon: LibraryIcon,
      link: "/library",
    },
  ];
  const patientMobileNavItems = [
    ...patientNavItems,
    {
      label: t("page_titles.settings_title"),
      icon: SettingsIcon,
      link: "/settings",
    },
  ];
  return { patientNavItems, patientMobileNavItems };
};

import React from "react";
import { DisplayFieldType, Field } from "types";
import {
  ImageDisplay,
  InfoBoxDisplayField,
  RichTextDisplayField,
  VideoEmbed,
} from "components";
import FileAttachmentFieldForDownload from "../../../../../../components/utility/GeneratedField/components/FileAttachmentFieldForDownload";
import "./DisplayFields.styles.scss";

const DisplayFields = ({ field }: { field: Field }) => {
  const fieldType = field.type as DisplayFieldType;
  switch (fieldType) {
    case "HEADING_FIELD":
      return <h3>{field.inlineValue}</h3>;
    case "PARAGRAPH_FIELD":
      return <p>{field.inlineValue}</p>;
    case "INFO_BOX":
      return (
        <InfoBoxDisplayField
          value={field.richText || ""}
          backgroundColor={field.colorTheme}
          icon={field.icon}
          iconAlignment={field.iconAlignment}
        />
      );
    case "IMAGE":
      return <ImageDisplay image={field.fileUrl || ""} />;
    case "RICH_TEXT":
      return <RichTextDisplayField value={field.richText || ""} />;
    case "VIDEO_EMBED":
      return (
        <div className="display-video-field">
          <p className="video-field__label">{field.label}</p>
          <VideoEmbed videoURL={field.videoURL} uuid={field.uuid as string} />
        </div>
      );
    case "FILE_ATTACHMENT":
      return (
        <FileAttachmentFieldForDownload
          uuid={field.uuid as string}
          fileId={field.fileId as string}
        />
      );
    default:
      return <></>;
  }
};

export default DisplayFields;

import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import ReactGA from "react-ga4";
interface Member {
  email: string;
  role: { value: string; label: string } | null;
  error?: string;
}

const useAddUsersToGroup = (groupId: string) => {
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const convertUsersToRQLFormat = (user: Member) => {
    if (user) {
      return `{ 
          email: "${user.email}",
          role: "${user?.role?.value}",
        }`;
    }

    return;
  };

  const formattedUsers = (users: Member[]) =>
    users?.map(convertUsersToRQLFormat) || [];

  const addUsersToGroup = async (args: Member[]) => {
    if (!args || !groupId) {
      return null;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              mutation {
                addUsersToGroup(
                users: [${formattedUsers(args)}],
                groupId: "${groupId}",
                ) {
                  success
                  addedEmails
                  errors {
                      email
                      message
                  }
                }
              }
            `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        const { addedEmails } = resData.data.addUsersToGroup;
        if (addedEmails && addedEmails.length > 0) {
          ReactGA.event("users_added_to_group_success", {
            user_id: user?.id,
            group_id: groupId,
            added_emails: addedEmails.join(", "),
          });
        }
        return resData.data.addUsersToGroup;
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    addUsersToGroup,
  };
};

export default useAddUsersToGroup;

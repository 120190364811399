import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import { useCallback } from "react";

const useResendVerificationCode = () => {
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const resendVerificationCode = useCallback(async () => {
    const timezoneOffset = new Date().getTimezoneOffset();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              mutation {
                resendVerificationCode(email: "${user?.email}")
              }
            `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-User-Timezone-Offset": timezoneOffset.toString(),
          permissionKey: "resend_code",
        },
      });

      const resData = await response.json();

      if (response.ok && resData?.data?.resendVerificationCode) {
        toast("Verification code sent, please check your email");
      } else {
        if (resData.errors && resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            resData?.data?.resendVerificationCode?.message ||
              "Failed to resend verification code."
          );
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while resending the verification code.");
    }
  }, [logOut, user?.email]);

  return {
    resendVerificationCode,
  };
};

export default useResendVerificationCode;

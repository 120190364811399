import { useState } from "react";
import { toast } from "react-toastify";
import useCurrentUser from "./useCurrentUser";

// Define the type for the arguments expected in `submitConfirmationEmailRequest`
interface ConfirmationEmailRequestArgs {
  email?: string;
}

const useRequestConfirmationEmail = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { user } = useCurrentUser();

  // Add appropriate typing for the `args` and return type `Promise<void>`
  const submitConfirmationEmailRequest = (
    args: ConfirmationEmailRequestArgs
  ): Promise<void> => {
    if (!args) {
      return Promise.resolve();
    }

    setIsSubmitting(true);

    return fetch(`${process.env.REACT_APP_API_URL}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        query: `
          mutation {
            requestConfirmation(email: "${args.email}")
          }
        `,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        orgId: user?.activeOrgId || "NA",
        permissionKey: "request_confirmation_email",
      },
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          setIsSubmitting(false);
          toast.error("Hmm, something went wrong.");
          throw new Error("Failed to send confirmation email request");
        }
        return res.json();
      })
      .then((resData) => {
        if (resData.errors) {
          setIsSubmitting(false);
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        } else {
          setIsSubmitting(false);
          toast(
            "Request sent! You should receive a new confirmation email soon."
          );
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.error("Error requesting confirmation email:", err);
      });
  };

  return {
    submitConfirmationEmailRequest,
    isSubmitting,
  };
};

export default useRequestConfirmationEmail;

import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLogout, useCurrentUser } from "utils/hooks";
import { deleteGroupAction } from "store/actions/groups";

const useDeleteGroup = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const history = useNavigate();

  const submitDeleteGroup = (groupId: string) => {
    if (!groupId) {
      return null;
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                deleteGroup(groupId: "${groupId}")
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            orgId: user?.activeOrgId || "NA",
            permissionKey: "delete_groups",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                setIsSubmitSuccessful(true);
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                setIsSubmitSuccessful(true);
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setIsSubmitSuccessful(true);
              resolve();
              return true;
            } else {
              toast("Your group has been deleted");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("group_deleted_success", {
                user_id: user?.id,
                group_id: groupId,
              });
              dispatch(deleteGroupAction(resData.data.deleteGroup));
              history("/groups/");
            }
          })
          .catch((err) => {
            setIsSubmitSuccessful(true);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitDeleteGroup,
    isSubmitSuccessful,
  };
};

export default useDeleteGroup;

import React from "react";
import { useTranslation } from "react-i18next";

interface FieldLabelProps {
  value?: string;
  required?: boolean | string;
}

const FieldLabel = ({ value, required }: FieldLabelProps) => {
  const { t } = useTranslation(["common"]);

  const isRequired = required && required !== "false";
  return (
    <>
      {value} {!isRequired ? t("common_labels.optional") : null}
    </>
  );
};

export default FieldLabel;

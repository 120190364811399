import React, { useState, useRef, useEffect } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import "./EmojiDropdown.styles.scss";

interface EmojiDropdownProps {
  currentEmoji?: string; // The initial emoji to display
  onEmojiSelect: (emoji: string) => void; // Callback for when an emoji is selected
}

const EmojiDropdown = ({ currentEmoji, onEmojiSelect }: EmojiDropdownProps) => {
  const [selectedEmoji, setSelectedEmoji] = useState<string | undefined>(
    currentEmoji
  );
  const [isPickerVisible, setPickerVisible] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle emoji selection
  const onEmojiClick = (emojiData: EmojiClickData) => {
    setSelectedEmoji(emojiData.emoji);
    onEmojiSelect(emojiData.emoji); // Pass selected emoji to parent
    setPickerVisible(false); // Close the picker
  };

  // Handle click outside to close the picker
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setPickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="emoji-dropdown-container" ref={dropdownRef}>
      <div
        className="emoji-trigger"
        onClick={() => setPickerVisible((prev) => !prev)}
        onKeyDown={() => setPickerVisible((prev) => !prev)}
        role="button"
        tabIndex={0}
      >
        <span className="selected-emoji">{selectedEmoji}</span>
      </div>

      {isPickerVisible && (
        <div className="emoji-picker-dropdown">
          <EmojiPicker
            onEmojiClick={onEmojiClick}
            height={400}
            previewConfig={{
              showPreview: false,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EmojiDropdown;

import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Input, Button, IconButton } from "components";
import { EMAIL_REGEX } from "utils/constants";
import { truncateText } from "utils/helpers";
import { Member } from "../../types/types";
import { useTranslation } from "react-i18next";
import "./InviteNewInput.styles.scss";

interface InviteNewInputProps {
  addedMembers: Member[];
  onAddMemberEmail: (member: Member) => void;
  onRemoveMemberEmail: (member: Member) => void;
  requiredEmailError: string;
  onUpdateRequiredEmailError: () => void;
}

const InviteNewInput = ({
  addedMembers,
  onAddMemberEmail,
  onRemoveMemberEmail,
  requiredEmailError,
  onUpdateRequiredEmailError,
}: InviteNewInputProps) => {
  const { t } = useTranslation(["common"]);
  const { handleSubmit, register, errors, setValue, setError, control } =
    useForm();

  const inputValue: string | undefined = useWatch({
    control,
    name: "email",
  });

  useEffect(() => {
    if (inputValue) {
      onUpdateRequiredEmailError();
    }
  }, [inputValue, onUpdateRequiredEmailError]);

  const onSubmit = (data: { email: string }) => {
    if (addedMembers.some((member: Member) => member.email === data.email)) {
      setError("email", {
        type: "custom",
        message: t("groups.add_member_panel.already_added_email_error"),
      });
      return;
    }
    onAddMemberEmail({ email: data.email, error: "" });
    setValue("email", "");
  };

  return (
    <div className="invite-new-input">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          name="email"
          type="email"
          label={t("groups.add_member_panel.invite_new_label")}
          placeholder={t("groups.add_member_panel.invite_new_placeholder")}
          forwardRef={register({
            required: t("form_errors.email_required"),
            pattern: {
              value: EMAIL_REGEX,
              message: t("form_errors.valid_email"),
            },
          })}
          error={errors?.email?.message || requiredEmailError}
          extraClass="invite-new-input__input"
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.key === "Enter" && e.preventDefault();
          }}
        />
        {addedMembers?.length > 0 && (
          <div className="invite-new-input__emails">
            {addedMembers.map((member: Member) => (
              <div key={member.email} className="invite-new-input__email">
                <div>
                  {truncateText(member.email, 50)}
                  {member.error && (
                    <div className="invite-new-input__error">
                      <small>{member.error}</small>
                    </div>
                  )}
                </div>
                <IconButton
                  icon="CloseCrossBold"
                  onClick={() => onRemoveMemberEmail(member)}
                  extraClass="invite-new-input__remove-btn"
                />
              </div>
            ))}
          </div>
        )}
        {inputValue && (
          <Button
            type="submit"
            name="add_member_button"
            value={t("groups.add_member_panel.add_label")}
            extraClass="invite-new-input__add-btn thin pill"
          />
        )}
      </form>
    </div>
  );
};

export default InviteNewInput;

import "./styles.scss";
import React from "react";
import { useForm, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { default as ReactSelect } from "react-select";
import Creatable from "react-select/creatable";
import makeAnimated from "react-select/animated";

interface SelectProps {
  options: any;
  name: string;
  label?: string;
  error?: string;
  forwardRef?: any;
  forwardControl?: any;
  hint?: string;
  rules?: any;
  defaultValue?: any;
  value?: any;
  menuPlacement?: any;
  className?: string;
  isMulti?: boolean;
  components?: any;
  onChange?: (options: any) => void;
  isCreatable?: boolean;
  onCreateOption?: (option: any) => void;
  placeholder?: string;
  isDisabled?: boolean;
  controlShouldRenderValue?: boolean;
  noOptionsMessage?: () => React.ReactNode;
}

const Select = ({
  options,
  name,
  label,
  error,
  forwardRef,
  forwardControl,
  hint,
  rules,
  defaultValue,
  value,
  menuPlacement,
  className,
  isMulti,
  onChange,
  isCreatable = false,
  onCreateOption,
  placeholder,
  isDisabled,
  controlShouldRenderValue,
  noOptionsMessage,
  ...props
}: SelectProps) => {
  const { control } = useForm();
  const animatedComponents = makeAnimated();
  const { t } = useTranslation(["common"]);
  const { field } = useController({
    name,
    control: forwardControl || control,
    rules,
    defaultValue: defaultValue || value || null,
  });

  const handleChange = (options: any) => {
    field.onChange(options);
    if (onChange) {
      onChange(options);
    }
  };

  const SelectComponent = isCreatable ? Creatable : ReactSelect;
  return (
    <div className={`select ${className ? className : ""}`} id={name}>
      <label className="select__label">{label}</label>
      <SelectComponent
        {...field}
        key={value || field.value}
        classNamePrefix="select"
        forwardRef={forwardRef}
        options={options}
        aria-label={label}
        className={`select__field ${error ? "error" : ""}`}
        menuPortalTarget={document.getElementById(`'${name}'`)}
        styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
        menuPlacement={menuPlacement}
        components={animatedComponents}
        isMulti={isMulti}
        value={value || field.value}
        onChange={handleChange}
        placeholder={placeholder || t("common_labels.select_placeholder")}
        onCreateOption={isCreatable ? onCreateOption : null}
        isDisabled={isDisabled}
        controlShouldRenderValue={controlShouldRenderValue}
        noOptionsMessage={noOptionsMessage}
        {...props}
      />
      {error && (
        <p className="select__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && hint !== "null" && (
        <p className="select__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default Select;

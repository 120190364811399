import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchFiles } from "utils/hooks";

interface UseGetGroupFilesProps {
  groupId?: string;
}

const useGetGroupLibrary = ({ groupId }: UseGetGroupFilesProps) => {
  const { fetchGroupFiles } = useFetchFiles();

  const files = useSelector(
    (state: RootStateOrAny) => state.files && state.files
  );

  useEffect(() => {
    if (groupId) {
      fetchGroupFiles(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    groupFiles: files?.groupFiles || [],
  };
};

export default useGetGroupLibrary;

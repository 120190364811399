import React from "react";
import { useNavigate } from "react-router-dom";
import { useAssignmentStore } from "../../assignmentStore";
import { AssignmentCard } from "components";

const AssignmentsForPatientsColumn = () => {
  const history = useNavigate();
  const { state } = useAssignmentStore();

  const filteredAssignments = state.assignments.filter(
    (a) => a.type !== "SAFETY_PLAN"
  );

  return (
    <>
      {filteredAssignments?.map((assignment: any) => (
        <AssignmentCard
          isActive={state.activeAssignment?.uuid === assignment.uuid}
          key={assignment.uuid}
          showSubtitle={false}
          assignment={assignment}
          hasActions={true}
          onCardClick={async () => {
            history(`/your-assignments/${assignment.slug}`);
          }}
        />
      ))}
    </>
  );
};

export default AssignmentsForPatientsColumn;

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./SingleSubmission.styles.scss";
import React, { useState, useEffect } from "react";
import {
  AlertBar,
  Button,
  GeneratedFieldResponse,
  Icon,
  GroupNameTag,
  Avatar,
} from "components";
import PatientSubmissionPrivacySetting from "pages/AppPages/PatientPages/AssignmentsForPatients/components/PatientSubmissionPrivacySetting";
import PatientSubmissionGroupPrivacySetting from "pages/AppPages/PatientPages/AssignmentsForPatients/components/PatientSubmissionGroupPrivacySetting";
import useEditSubmission from "utils/hooks/submissions/useEditSubmission";
import EditIcon from "assets/icons/pen-to-square.svg";
import { useCurrentUser, useGetGroupUsers } from "utils/hooks";
import AssignmentsForPatientsFieldsColumn from "../../../../PatientPages/AssignmentsForPatients/components/AssignmentsForPatientsFieldsColumn";
import { ResponseField } from "types";
import { obfuscateEmail } from "utils/helpers";

interface SingleSubmissionProps {
  submission: any;
  groupId?: string;
}

const SingleSubmission = ({ submission, groupId }: SingleSubmissionProps) => {
  const [isPrivate, setIsPrivate] = useState(submission?.privacy);
  const [isGroupPrivate, setIsGroupPrivate] = useState(
    submission?.groupPrivacy
  );
  const [isEditing, setIsEditing] = useState(false);
  const { updateSubmissionPrivacy } = useEditSubmission({
    privacySetting: isPrivate,
    groupPrivacySetting: isGroupPrivate,
  });

  const { groupUser } = useGetGroupUsers({
    groupId: groupId ? groupId : null,
    userId: submission?.userId,
  });

  useEffect(() => {
    if (isGroupPrivate === false) {
      setIsPrivate(false);
    }
  }, [isGroupPrivate]);

  const handlePrivacyChange = (type: "privacy" | "groupPrivacy") => {
    if (submission) {
      if (type === "privacy") {
        setIsPrivate(!isPrivate);
      } else if (type === "groupPrivacy") {
        setIsGroupPrivate(!isGroupPrivate);
      }

      updateSubmissionPrivacy({
        submissionId: submission?.uuid.toString(),
        assignment: null,
        privacySetting: type === "privacy" ? !isPrivate : isPrivate,
        groupPrivacySetting:
          type === "groupPrivacy" ? !isGroupPrivate : isGroupPrivate,
      });
    }
  };

  const handleEditingChange = () => {
    setIsEditing(!isEditing);
  };

  const isEditingFeatureEnabled = false;

  const { isPatient } = useCurrentUser();

  const isGroupTab = groupId;

  return (
    <div className="single-submission-page">
      {isPatient && (
        <div className="single-submission-header">
          {submission?.groupName && isGroupTab && (
            <div className="flex align-center">
              <div className="single-submission-header__avatar-icon">
                <Avatar user={groupUser} />
              </div>
              {obfuscateEmail(groupUser?.email)}
            </div>
          )}
          {submission?.groupName && (
            <GroupNameTag groupName={submission.groupName} />
          )}
          {!isGroupTab && (
            <>
              {submission?.groupId && (
                <PatientSubmissionGroupPrivacySetting
                  onGroupPrivacySettingChange={() =>
                    handlePrivacyChange("groupPrivacy")
                  }
                  isGroupPrivate={isGroupPrivate}
                />
              )}
              <PatientSubmissionPrivacySetting
                isPrivate={isPrivate}
                onPrivacySettingChange={() => handlePrivacyChange("privacy")}
                isDisabled={submission?.groupId && !isGroupPrivate}
              />
            </>
          )}
          {isEditingFeatureEnabled ? (
            <>
              {isEditing ? (
                <Button
                  name={"Save"}
                  onClick={handleEditingChange}
                  value="Save"
                  size="small"
                />
              ) : (
                <div
                  className="single-submission-header"
                  onClick={() => setIsEditing(true)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => setIsEditing(true)}
                >
                  <div className="single-submission-edit-icon">
                    <Icon src={EditIcon} />
                  </div>
                  <div className="single-submission-edit-label">
                    <p className="single-submission-edit-label-text">Edit</p>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      )}
      {isEditing ? (
        <AssignmentsForPatientsFieldsColumn />
      ) : (
        <>
          {submission?.isFlagged && (
            <AlertBar
              content={
                <div className="white-text">
                  <p>
                    Sentiment Analysis has indicated that this submission may
                    contain text related to self-harm, violence or deep
                    depression.
                  </p>
                  <p>
                    Please note that Sentiment Analysis is in beta, which means
                    it should not be relied upon for any clinical
                    determinations.
                  </p>
                  <p>
                    If you feel that this is wrongfully flagged, please{" "}
                    <a
                      href="mailto:support@reflectiveapp.com"
                      className="white-text underlined"
                    >
                      <b>let us know</b>
                    </a>
                    .
                  </p>
                </div>
              }
              type="error"
              rounded
              padded
            />
          )}
          {submission?.sections?.map((section: any) => {
            return (
              <div key={section.uuid}>
                {submission?.sections?.length === 1 &&
                section.sectionLabel === "Page 1" ? undefined : (
                  <h3 className="single-submission-page__section-label">
                    {section.sectionLabel}
                  </h3>
                )}
                <div>
                  {section.responseFields.map((field: ResponseField) => {
                    return (
                      <div key={field.uuid}>
                        {![
                          "HEADING_FIELD",
                          "PARAGRAPH_FIELD",
                          "FILE_ATTACHMENT",
                          "INFO_BOX",
                          "VIDEO_EMBED",
                        ].includes(field.fieldType) ? (
                          <p className="single-submission-page__field-label">
                            {field.fieldLabel}
                          </p>
                        ) : undefined}
                        <GeneratedFieldResponse field={field} />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SingleSubmission;

import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import GroupMembersTable from "./components/GroupMembersTable/GroupMembersTable";
import {
  useFetchGroupUsers,
  useGetGroupUsers,
  useGetGroups,
} from "utils/hooks";
import GroupMembersTableEmptyState from "./components/GroupMembersTableEmptyState/GroupMembersTableEmptyState";
import { PageActionBar, SidePanel, Tabs } from "components";
import AddMemberButton from "./components/AddMemberButton/AddMemberButton";
import AddMemberForm from "./components/AddMemberForm/AddMemberForm";
import useAddMemberFormTabs from "./components/AddMemberForm/useAddMemberFormTabs";
import { useTranslation } from "react-i18next";

const GroupMembers = () => {
  const { t } = useTranslation(["common"]);
  const { uuid } = useParams();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const MemoizedGroupMembersTable = React.memo(GroupMembersTable);
  const { fetchGroupUsers } = useFetchGroupUsers();
  const { groupUsers } = useGetGroupUsers({ groupId: group.uuid });
  const memoizedGroupMembers = useMemo(() => groupUsers, [groupUsers]);
  const { addMemberFormTabs, activeTab, handleTabChange } =
    useAddMemberFormTabs("add");

  const [showAddMemberPanel, setShowAddMemberPanel] = useState(false);

  const handleAddMemberPanelClose = () => {
    setShowAddMemberPanel(false);
  };

  const handleAddNewMember = () => {
    setShowAddMemberPanel(true);
  };

  useEffect(() => {
    fetchGroupUsers(group.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.uuid]);

  return (
    <div>
      <PageActionBar>
        <AddMemberButton onClick={handleAddNewMember} />
      </PageActionBar>
      {groupUsers?.length > 0 ? (
        <MemoizedGroupMembersTable
          groupId={group.uuid}
          data={memoizedGroupMembers}
        />
      ) : (
        <GroupMembersTableEmptyState />
      )}
      <SidePanel
        title={t("groups.add_member_panel.add_to_group_label", {
          groupName: group.name,
        })}
        isVisible={showAddMemberPanel}
        onClose={handleAddMemberPanelClose}
      >
        <Tabs
          items={addMemberFormTabs}
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          size="medium"
          placement="left"
          extraClass="add-member-panel-tabs"
        />
        <AddMemberForm
          group={group}
          onSuccess={handleAddMemberPanelClose}
          activeTab={activeTab.key}
        />
      </SidePanel>
    </div>
  );
};

export default GroupMembers;

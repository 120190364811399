const setAuthenticatedUser = (user) => ({
  type: "SET_AUTH_USER",
  payload: user,
});

const verifyAuthenticatedUser = () => ({
  type: "VERIFY_USER",
});

export { setAuthenticatedUser, verifyAuthenticatedUser };

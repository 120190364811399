import React, { useEffect } from "react";
import AssignmentsForPatients from "../AssignmentsForPatients";
import {
  AssignmentDispatchContext,
  AssignmentStateContext,
  useAssignmentStoreDefaults,
} from "../AssignmentsForPatients/assignmentStore";
import {
  getAssignments,
  getSubmissions,
} from "../AssignmentsForPatients/useSubmissionsApi";

const AssignmentContextWrapper = () => {
  const { assignmentState, setAssignmentState } = useAssignmentStoreDefaults();

  useEffect(() => {
    const fetchData = async () => {
      if (assignmentState.initialized) return;
      const submissions = await getSubmissions();
      const assignments = await getAssignments();
      setAssignmentState({
        assignments,
        submissions,
      });
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AssignmentStateContext.Provider value={assignmentState}>
      <AssignmentDispatchContext.Provider value={setAssignmentState}>
        <AssignmentsForPatients />
      </AssignmentDispatchContext.Provider>
    </AssignmentStateContext.Provider>
  );
};

export default AssignmentContextWrapper;

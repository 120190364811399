export const defaultColors = [
  "#47C9D1",
  "#083D77",
  "#4A4A4A",
  "#92B2D0",
  "#F36173",
  "#F6BA3F",
  "#946195",
  "#4CA6E1",
  "#d7d7d7",
];

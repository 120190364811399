import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLogout, useCurrentUser } from "utils/hooks";
import { deleteInviteCodeAction } from "store/actions/inviteCodes";

const useDeleteInviteCode = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const deleteInviteCode = async (codeId?: string) => {
    if (!codeId) {
      return;
    }
    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/invite-code/${codeId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            orgId: user?.activeOrgId || "NA",
            permissionKey: "delete_invite_codes",
          },
        }
      );

      const resData = await res.json();

      if (res.ok) {
        dispatch(deleteInviteCodeAction(resData.codeId));
        toast("Invite code deleted :)");
        ReactGA.event("invite_code_deleted", {
          user_id: user?.id,
          code_id: codeId,
        });
      }

      if (!res.ok) {
        if (resData.message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(resData.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    deleteInviteCode,
  };
};

export default useDeleteInviteCode;

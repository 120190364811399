import React, { useMemo } from "react";
import { AssignmentIcon } from "components";
import { toAmericanDateFromUnix, toTimeFromUnix } from "utils/helpers";
import { useTranslation } from "react-i18next";

const usePatientSubmissionsDataColumns = (showGroupColumns?: boolean) => {
  const { t } = useTranslation(["common"]);
  const patientSubmissionDataColumns = useMemo(() => {
    const baseColumns = [
      {
        header: t("patient_assignments.assignment_type_label"),
        accessorKey: "assignmentLabel",
        cell: (props: any) => {
          return (
            <div className="flex">
              <AssignmentIcon
                style={props.row.original.assignmentLabel}
                color={props.row.original.color}
                icon={props.row.original.icon?.src}
                variant="small"
              />
              {props.row.original.assignmentLabel}
            </div>
          );
        },
      },
      {
        header: t("patient_assignments.submission_date_label"),
        accessorKey: "dateField",
        cell: (props: any) => {
          return (
            <>
              <span>
                {toAmericanDateFromUnix(props.row.original.createdAt)} at{" "}
                {toTimeFromUnix(props.row.original.createdAt)}
              </span>
            </>
          );
        },
      },
      {
        header: t("patient_assignments.privacy_label"),
        accessorKey: "privacy",
        cell: (props: any) => {
          return (
            <>
              <span>
                {props.row.original.privacy
                  ? t("patient_assignments.privacy_private_label")
                  : t("patient_assignments.privacy_shared_label")}
              </span>
            </>
          );
        },
      },
    ];

    if (showGroupColumns) {
      return [
        ...baseColumns,
        {
          header: t("patient_assignments.group_privacy_label"),
          accessorKey: "groupPrivacy",
          cell: (props: any) => (
            <>
              {props.row.original.groupPrivacy
                ? t("patient_assignments.privacy_private_label")
                : t("patient_assignments.privacy_shared_label")}
            </>
          ),
        },
        {
          header: t("patient_assignments.group_label"),
          accessorKey: "groupName",
          cell: (props: any) => <>{props.row.original.groupName}</>,
        },
      ];
    }

    return baseColumns;
  }, [t, showGroupColumns]);

  return patientSubmissionDataColumns;
};

export default usePatientSubmissionsDataColumns;

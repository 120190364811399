import { TabItem } from "components/elements/Tabs/Tabs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type ButtonName = "invite" | "add";

const useAddMemberFormTabs = (buttonName: ButtonName) => {
  const { t } = useTranslation(["common"]);
  const addMemberFormTabs: TabItem[] = [
    {
      name: t("groups.add_member_panel.invite_new_label"),
      url: null,
      key: "invite",
      actionComponent: null,
      mainComponent: null,
    },
    {
      name: t("groups.add_member_panel.add_existing_label"),
      url: null,
      key: "add",
      actionComponent: null,
      mainComponent: null,
    },
  ];
  const [activeTab, setActiveTab] = useState(
    buttonName === "invite" ? addMemberFormTabs[0] : addMemberFormTabs[1]
  );

  const handleTabChange = (tab: TabItem) => {
    setActiveTab(tab);
  };

  return {
    addMemberFormTabs,
    activeTab,
    handleTabChange,
  };
};

export default useAddMemberFormTabs;

import "./AuthenticationLayout.styles.scss";
import React from "react";
import { Animate, Logo } from "components";
import useAuthRedirect from "./utils/useAuthRedirect";

const AuthenticationLayout = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | Iterable<React.ReactNode>
    | null
    | undefined;
  fullHeight?: boolean;
}) => {
  useAuthRedirect();

  return (
    <>
      <div className="flex">
        <section className="section mlg-3 left-side">
          <div className="auth-content">
            <Animate animation="fadeInLeft">
              <Logo />
            </Animate>

            {props.children}
          </div>
        </section>
        <div
          className={`mlg-5 v7AndUp right-side ${
            props.fullHeight ? "full-height" : ""
          }`}
        />
      </div>
    </>
  );
};

export default AuthenticationLayout;

import React from "react";
import "./Emoji.styles.scss";
import { EmojiType } from "types";

interface Props {
  emoji: string | EmojiType;
  style?: "horizontal" | "vertical";
  background?: "white" | "transparent";
  extraClass?: "condensed";
}

export function Emoji({
  emoji,
  style = "vertical",
  background = "white",
  extraClass,
}: Props) {
  // Determine if emoji is a string or EmojiType and handle accordingly
  const emojiDisplay = typeof emoji === "string" ? emoji : emoji.emoji;
  const emojiLabel = typeof emoji === "string" ? "" : emoji.label;

  return (
    <div className={`emoji-container ${style} ${extraClass}`}>
      <div className={`emoji-wrapper ${style}`}>
        <div className={`emoji-box ${background}`}>{emojiDisplay}</div>
        {emojiLabel && <div className={"emoji-label"}>{emojiLabel}</div>}
      </div>
    </div>
  );
}

import {
  Assignment,
  AssignmentSubmission,
  CreateSubmission,
  ResponseField,
} from "types";
import { toast } from "react-toastify";
import { displayFields } from "../../TherapistPages/AssignmentBuilder/components/AssignmentBuilderMenu/fieldTypes";

function tabs(indent = 1) {
  return "\t".repeat(indent);
}
const submissionMutation = (
  submission: CreateSubmission & { uuid?: string },
  responseFields?: ResponseField[],
  responseUpdateFields?: ResponseField[]
) => {
  const escape = (str: string) =>
    str.replace(/"/g, '\\"').replace(/\n/g, "\\n");
  const parseValue = (value: any) => {
    //is array?
    if (Array.isArray(value)) {
      return `"${escape(JSON.stringify(value))}"`;
    }
    switch (typeof value) {
      case "string":
        return `"${escape(value)}"`;
      case "number":
        return `"${value}"`;
      case "object":
        return `"${JSON.stringify(value)}"`;
      default:
        return value || `""`;
    }
  };
  const fieldRql = (field: ResponseField) => {
    return `{
      ${field.uuid ? `uuid: "${field.uuid}"` : ""}
      fieldId: "${field.fieldId}"
      fieldLabel: "${field.fieldLabel}"
      fieldType: "${field.fieldType}"
      sectionId: "${field.sectionId}"
      sectionLabel: "${field.sectionLabel}"
      value: ${parseValue(field.value)}
        }`;
  };
  const noIds = responseFields?.map((field) => fieldRql(field));
  const withIds = responseUpdateFields?.map((field) => fieldRql(field));
  return `submission: {
    ${submission.uuid ? `${tabs(3)}uuid:"${submission.uuid}"` : ""}
    assignmentId: "${submission.assignmentId}"
    assignmentLabel: "${submission.assignmentLabel}"
    privacy: ${submission.privacy}
    groupPrivacy: ${submission.groupPrivacy}
    isDraft: ${submission.isDraft}
    }
    ${noIds ? `responseFields:[${noIds}]` : ""}
    ${withIds ? `responseUpdateFields:[${withIds}]` : ""}
    `;
};

type RqlResponse = (fields: string[], indent?: number) => string;
const rqlObject: RqlResponse = (fields, indent = 1) => {
  const tabs = "\t".repeat(indent);
  return `{\n${tabs}${fields.join(`\n${tabs}`)}\n${"\t".repeat(indent - 1)}}`;
};

const responseFieldsRql =
  "responseFields" +
  rqlObject([
    "uuid",
    "submissionId",
    "sectionId",
    "sectionLabel",
    "fieldId",
    "fieldLabel",
    "fieldType",
    "value",
  ]);
const submissionRql = rqlObject([
  "uuid",
  "createdAt",
  "slug",
  "userId",
  "assignmentId",
  "assignmentLabel",
  "privacy",
  "groupPrivacy",
  "isDraft",
  responseFieldsRql,
]);

export const createSubmission = async (
  submission: CreateSubmission,
  responseFields: ResponseField[]
) => {
  const withNoId = responseFields.filter(
    (field) => !displayFields.includes(field.fieldType)
  );

  const mutation = submissionMutation(submission, withNoId);
  const query = `mutation {\n\tcreateSubmission(\n${mutation})${submissionRql}\n}`;
  const response = await serverCall<{
    data: { createSubmission: AssignmentSubmission };
  }>(query);

  return response.data.createSubmission;
};

export const updateSubmission = async (
  submission: CreateSubmission & { uuid: string },
  responseFields: ResponseField[]
) => {
  const mutation = submissionMutation(submission, undefined, responseFields);
  const query = `mutation {\n\tupdateSubmission(\n${mutation})${submissionRql}\n}`;

  const response = await serverCall<{
    data: { updateSubmission: AssignmentSubmission };
  }>(query);
  return response.data.updateSubmission;
};

export const getSubmissions = async () => {
  const response = await serverCall<{
    data: { getAllSubmissionsByCreatorId: AssignmentSubmission[] };
  }>(`
              query {
                getAllSubmissionsByCreatorId {
                  uuid
                  createdAt
                  slug
                  userId
                  assignmentId
                  assignmentLabel
                  privacy
                  color
                  isDraft
                  groupId
                  groupName
                  groupPrivacy
                  icon {
                    name
                    src
                  }
                  responseFields {
                    uuid
                    submissionId
                    sectionId
                    sectionLabel
                    sectionOrder
                    fieldId
                    fieldLabel
                    fieldType
                    value
                  }
                }
              }
          `);
  // TODO fetchSubmissionsAction(response.data.getAllSubmissionsByCreatorId);
  return response.data.getAllSubmissionsByCreatorId;
};
export const getAssignments = async () => {
  const response = await serverCall<{
    data: {
      getAllAssignmentsByUserId: Assignment[];
    };
  }>(
    `
              query {
                getAllAssignmentsByUserId {
                    category
                    uuid
                    slug
                    type
                    label
                    status
                    actionPlanId
                    patientId
                    instructions
                    createdAt
                    groupId
                    icon {
                      name
                      src
                    }
                    color
                    notification {
                      startDate
                      endDate
                      notificationTime
                      notificationMessage
                      daysOfWeek
                      status
                    }
                    sections {
                      uuid
                      name
                      slug
                      assignmentId
                      createdAt
                      type
                      label
                      status
                      order
                      isDefaultStart
                      description
                      fields {
                        uuid
                        name
                        sectionId
                        type
                        label
                        status
                        placeholder
                        hint
                        defaultValue
                        choicesKey
                        required
                        order
                        max
                        min
                        config
                        inlineValue
                        videoURL
                        fileId
                        fileUrl
                        fromPrevious
                        pinTitle
                        pinToSection
                        fromPreviousField
                        icon
                        iconAlignment
                        colorTheme
                        moodScale {
                            label
                            description
                            emojis {
                                emoji
                                label
                            }
                        }
                        usePrevious
                        richText
                        multiResponseOptions {
                          placeholder
                        }
                        options
                        moods
                      }    
                    }
                }
              }
          `
  );
  return response.data.getAllAssignmentsByUserId;
};
const serverCall = async <T>(query: string): Promise<T> => {
  const req = JSON.stringify({ query });
  const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
    method: "POST",
    credentials: "include",
    body: req,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  if (res.status > 299) {
    const error = await res.json();
    if (error.errors[0].message.includes("BRKN_6001")) {
      toast.warn(`For security purposes please log in again.`);
      // TODO: Logout
    } else {
      toast.error(`Hmm, something went wrong.`);
      throw new Error("Failed!");
    }
  }
  const resData = await res.json();
  if (resData.errors) {
    toast.error(`Hmm, something went wrong. ${resData.errors[0].message}`);
  }
  return resData;
};

import React, { useRef, useState, useEffect } from "react";
import { Input } from "components";
import { checkIsCodeFull } from "utils/checkIsCodeFull";
import "./AuthCodeInput.styles.scss";

interface AuthCodeInputProps {
  numberOfDigits?: number;
  onCodeChange: (code: string[]) => void;
  currentError?: string;
}

const AuthCodeInput = ({
  numberOfDigits = 6,
  onCodeChange,
  currentError,
}: AuthCodeInputProps) => {
  const [authCode, setAuthCode] = useState<string[]>(
    new Array(numberOfDigits).fill("")
  );
  const inputRefs = useRef<HTMLInputElement[]>(
    new Array(numberOfDigits).fill(null)
  );

  const numericStringRegex = new RegExp(`^\\d{${numberOfDigits}}$`);

  const isCodeFull = checkIsCodeFull(authCode);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (value: string, index: number) => {
    if (isNaN(Number(value))) {
      return;
    }

    const newAuthCode = [...authCode];
    newAuthCode[index] = value;
    setAuthCode(newAuthCode);

    if (value && index < numberOfDigits - 1) {
      inputRefs.current[index + 1].focus();
    }

    onCodeChange(newAuthCode);
  };

  const handleBackspacePress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      e.key === "Backspace" &&
      !(e.target as HTMLInputElement).value &&
      index > 0
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedString = e.clipboardData.getData("text").trim();

    if (numericStringRegex.test(pastedString)) {
      setAuthCode(pastedString.split(""));
      if (inputRefs.current[numberOfDigits - 1]) {
        inputRefs.current[numberOfDigits - 1].focus();
      }
      onCodeChange(pastedString.split(""));
    }
  };

  return (
    <div className="auth-code-wrapper">
      <div className="auth-code-wrapper__inputs" onPaste={handlePaste}>
        {authCode.map((digit, index) => {
          return (
            <Input
              key={index}
              type="text"
              maxLength={1}
              inputMode="numeric"
              value={digit}
              forwardRef={(ref: HTMLInputElement) =>
                (inputRefs.current[index] = ref)
              }
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleBackspacePress(e, index)}
              extraClass="auth-code-wrapper__input"
              inputExtraClass={`auth-code-wrapper__input-field ${
                currentError && !digit && "error"
              }`}
            />
          );
        })}
      </div>
      {currentError && !isCodeFull && (
        <p className="auth-code-wrapper__error">
          <small>{currentError}</small>
        </p>
      )}
    </div>
  );
};

export default AuthCodeInput;

import React, { useEffect, useState } from "react";
import "./ConfigureAssignment.styles.scss";
import { Assignment, Field, Section } from "types";
import AssignmentFields from "../AssignmentDetails/AssignmentFields";
import FieldConfigure from "./components/FieldConfigure";
import { Button } from "components";
import { useUpdateAssignment, useGetAssignments } from "utils/hooks";
import { areDeeplyEqual } from "./components/FieldConfigure/utils";

type Props = {
  assignment: Assignment;
  canSaveSettings: boolean;
  setCanSaveSettings?: (data: boolean) => void;
  isGroup?: boolean;
};

type GenerateField = (
  field: Field,
  section: Section,
  originalAssignment?: Assignment
) => any;

const ConfigureAssignment = ({
  assignment,
  canSaveSettings,
  setCanSaveSettings,
  isGroup,
}: Props) => {
  const {
    assignment: defaultAssignment,
    groupAssignment: defaultGroupAssignment,
  } = useGetAssignments({
    assignmentId: assignment?.uuid,
  });

  const [activeAssignment, setActiveAssignment] = useState({
    ...structuredClone(isGroup ? defaultGroupAssignment : defaultAssignment),
  });

  const {
    updateAssignmentForPatient,
    updateAssignmentForGroup,
    unsavedConfigureSettings,
    isLoading,
    updateUnsavedConfigSettings,
  } = useUpdateAssignment();

  useEffect(() => {
    if (canSaveSettings && unsavedConfigureSettings) {
      setCanSaveSettings && setCanSaveSettings(false);
      handleSubmit();
    }
  });

  useEffect(() => {
    if (!activeAssignment || !defaultAssignment) return;
    const areUpdates = areDeeplyEqual(
      activeAssignment,
      isGroup ? defaultGroupAssignment : defaultAssignment
    );
    if (unsavedConfigureSettings === !areUpdates) return;
    updateUnsavedConfigSettings(!areUpdates);
  }, [
    activeAssignment,
    defaultAssignment,
    defaultGroupAssignment,
    unsavedConfigureSettings,
    updateUnsavedConfigSettings,
    isGroup,
  ]);

  const handleSubmit = () => {
    isGroup
      ? updateAssignmentForGroup(activeAssignment)
      : updateAssignmentForPatient(activeAssignment);
  };

  const generateField: GenerateField = (field, section, assignm) => {
    return (
      <FieldConfigure
        key={field.uuid}
        field={field}
        section={section}
        setActiveAssignment={setActiveAssignment}
        originalAssignment={assignm}
      />
    );
  };

  return (
    <div className="configure_container">
      <AssignmentFields
        assignment={activeAssignment}
        originalAssignment={
          isGroup ? defaultGroupAssignment : defaultAssignment
        }
        fieldComponent={generateField}
        panel={"Configure"}
      />
      {unsavedConfigureSettings && (
        <Button
          onClick={handleSubmit}
          name={`save_assignment_notifications_button`}
          value="Save Configuration"
          extraClass="save-settings"
          size="big"
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default ConfigureAssignment;

import React, { useState } from "react";
import { TabItem } from "components/elements/Tabs/Tabs";
import { useTranslation } from "react-i18next";
import { Group } from "types";
import PatientsSubmissionsTable from "../components/PatientSubmissionsTable/PatientsSubmissionsTable";

const usePatientSubmissionsTabs = (groups: Group[]) => {
  const { t } = useTranslation(["common"]);
  let patientSubmissionsTabs: TabItem[] = [
    {
      name: t("submissions.mine_label"),
      url: null,
      key: "mine",
      actionComponent: null,
      mainComponent: <PatientsSubmissionsTable showGroupColumns={true} />,
    },
  ];

  if (groups && groups.length > 0) {
    const groupTabs = groups.map((group) => ({
      name: group.name,
      url: null,
      key: group.name.toLowerCase(),
      actionComponent: null,
      mainComponent: <PatientsSubmissionsTable groupId={group.uuid} />,
    }));

    patientSubmissionsTabs = [...patientSubmissionsTabs, ...groupTabs];
  }

  const [activeTab, setActiveTab] = useState(patientSubmissionsTabs[0]);

  const handleTabChange = (tab: TabItem) => {
    setActiveTab(tab);
  };

  return {
    patientSubmissionsTabs,
    activeTab,
    handleTabChange,
  };
};

export default usePatientSubmissionsTabs;

import { useDispatch } from "react-redux";
import {
  fetchAssignmentsAction,
  fetchGroupAssignmentsAction,
} from "store/actions/assignments";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import { useCallback } from "react";

const useFetchAssignments = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const fetchAllAssignments = useCallback(() => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              query {
                getAllAssignmentsByUserId {
                    uuid
                    slug
                    type
                    label
                    status
                    actionPlanId
                    patientId
                    instructions
                    createdAt
                    category
                    groupId
                    icon {
                      name
                      src
                    }
                    color
                    notification {
                      startDate
                      endDate
                      notificationTime
                      notificationMessage
                      daysOfWeek
                      status
                    }
                    sections {
                      uuid
                      name
                      slug
                      assignmentId
                      createdAt
                      type
                      label
                      status
                      order
                      description
                      isDefaultStart
                      fields {
                        uuid
                        name
                        sectionId
                        type
                        label
                        status
                        placeholder
                        hint
                        defaultValue
                        choicesKey
                        required
                        order
                        max
                        min
                        config
                        inlineValue
                        videoURL
                        fileId
                        fileUrl
                        fromPrevious
                        pinTitle
                        pinToSection
                        fromPreviousField
                        usePrevious
                        richText
                        icon
                        iconAlignment
                        colorTheme
                        moodScale {
                            uuid
                            label
                            description
                            emojis {
                                emoji
                                label
                            }
                        }
                        multiResponseOptions {
                          placeholder
                        }
                        options
                        moods
                      }    
                    }
                }
              }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          orgId: user?.activeOrgId || "NA",
          permissionKey: "read_assignments",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchAssignmentsAction(resData.data.getAllAssignmentsByUserId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [dispatch, logOut, user?.activeOrgId]);

  const fetchGroupAssignments = useCallback(
    (groupId?: string | null) => {
      if (!groupId) {
        return null;
      }
      return new Promise(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              query {
                getAllAssignmentsByGroupId(groupId: "${groupId}") {
                    uuid
                    slug
                    type
                    label
                    status
                    actionPlanId
                    instructions
                    createdAt
                    groupId
                    category
                    icon {
                      name
                      src
                    }
                    color
                    notification {
                      startDate
                      endDate
                      notificationTime
                      notificationMessage
                      daysOfWeek
                      status
                    }
                    sections {
                      uuid
                      name
                      slug
                      assignmentId
                      createdAt
                      type
                      label
                      status
                      order
                      description
                      isDefaultStart
                      fields {
                        uuid
                        name
                        sectionId
                        type
                        label
                        status
                        placeholder
                        hint
                        defaultValue
                        choicesKey
                        required
                        order
                        max
                        min
                        config
                        inlineValue
                        icon
                        colorTheme
                        iconAlignment
                        videoURL
                        fileId
                        fileUrl
                        fromPrevious
                        pinTitle
                        pinToSection
                        fromPreviousField
                        usePrevious
                        richText
                        icon
                        iconAlignment
                        colorTheme
                        moodScale {
                            uuid
                            label
                            description
                            emojis {
                                emoji
                                label
                            }
                        }
                        multiResponseOptions {
                          placeholder
                        }
                        options
                        moods
                      }
                    }
                }
              }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                fetchGroupAssignmentsAction(
                  resData.data.getAllAssignmentsByGroupId
                )
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    [dispatch, logOut]
  );

  return {
    fetchAllAssignments,
    fetchGroupAssignments,
  };
};

export default useFetchAssignments;

import { useEffect, useMemo, useState } from "react";
import {
  useCurrentUser,
  useFetchSubmissions,
  useGetSubmissions,
  useGetPatients,
  useGetAssignments,
} from "utils/hooks";

const useSubmissionUtils = (groupId?: string) => {
  const { isTherapist, user } = useCurrentUser();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { patients } = useGetPatients({});
  const { hasAssignments } = useGetAssignments({});
  const [currentPatient, setCurrentPatient] = useState<any>(null);
  const { submissions: patientSubmissions } = useGetSubmissions({});
  const { groupSubmissions } = useGetSubmissions({ groupId });

  const submissions = groupId ? groupSubmissions : patientSubmissions;

  const [filteredSubmissions, setFilteredSubmissions] = useState(submissions);
  const memoizedSubmissions = useMemo(
    () => filteredSubmissions,
    [filteredSubmissions]
  );

  const sortedSubmissions = memoizedSubmissions
    .slice()
    .sort((a: { createdAt: number }, b: { createdAt: number }) =>
      b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
    );

  const handleSearch = (searchedSubmissions: any) => {
    setFilteredSubmissions(searchedSubmissions);
  };
  useEffect(() => {
    fetchAllSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setFilteredSubmissions(submissions);
  }, [submissions]);

  const handlePatientChange = (patient: any) => {
    const patientSubmissions = submissions.filter(
      (submission: { userId: any }) => submission.userId === patient?.value
    );

    setCurrentPatient(patient);
    if (patient) {
      setFilteredSubmissions(patientSubmissions);
    } else {
      setFilteredSubmissions(submissions);
    }
  };

  const hasSubmissions = submissions.length > 0;
  const hasAPatient = patients.length === 1;
  const hasPatients = patients.length > 1;
  const hasAnyPatients = hasAPatient || hasPatients;

  const firstPatient = patients[0];

  return {
    hasSubmissions,
    submissions,
    currentPatient,
    handleSearch,
    handlePatientChange,
    sortedSubmissions,
    firstName: user?.firstName,
    showFilter: isTherapist && hasPatients,
    showTableElements: hasSubmissions,
    showConfirmEmailBar: true,
    hasAnyPatients,
    firstPatient,
    hasAssignments,
  };
};

export default useSubmissionUtils;

import "./SubmissionsTablePatientEmptyState.styles.scss";
import React from "react";
import BalloonTwo from "assets/images/balloon-1.png";
import { useGetPatients } from "utils/hooks";
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";

interface SubmissionsTablePatientEmptyStateProps {
  patientId?: string;
  isTherapist?: boolean;
  groupId?: string;
}

const SubmissionsTablePatientEmptyState = ({
  patientId,
  isTherapist,
  groupId,
}: SubmissionsTablePatientEmptyStateProps) => {
  const { t } = useTranslation(["common"]);
  const { patient } = useGetPatients({ slug: patientId });
  const { uuid } = useParams();

  const isGroup = groupId || uuid?.includes("group");

  return (
    <div className="submissions-table-empty-state">
      {isTherapist ? (
        <>
          <h3>{t("submissions.empty_state_header")}</h3>
          <p>
            <Trans
              i18nKey="common:submissions.empty_state_paragraph"
              values={{
                patientName: isGroup
                  ? t("submissions.default_group")
                  : patient?.firstName || t("submissions.default_patient"),
              }}
            />
          </p>
        </>
      ) : (
        <>
          <h3>
            {isGroup
              ? t("submissions.group_empty_state_header")
              : t("submissions.patient_empty_state_header")}
          </h3>
          <p>
            {isGroup
              ? t("submissions.group_empty_state_paragraph")
              : t("submissions.patient_empty_state_paragraph")}
          </p>
        </>
      )}
      <div className="submissions-table-empty-state__image-holder">
        <img
          className="submissions-table-empty-state__image"
          src={BalloonTwo}
          alt="green and purple balloon"
        />
      </div>
    </div>
  );
};

export default SubmissionsTablePatientEmptyState;

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import AuthenticationHeader from "../components/AuthenticationHeader";
import AuthenticationFooter from "../components/AuthenticationFooter";
import { Animate, Button, Input, Link } from "components";

import { useCurrentUser, useLogin, useQueryParams } from "utils/hooks";
import { useNavigate } from "react-router-dom";

const LogIn = () => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm();
  const history = useNavigate();
  const { user, isAuthenticated, isVerified, isPatient } = useCurrentUser();
  useEffect(() => {
    const destination = isPatient ? "/your-assignments" : "/dashboard";
    if (user && isAuthenticated && isVerified) {
      history(destination);
    }
  }, [history, isAuthenticated, isPatient, isVerified, user]);
  const { submitLogin } = useLogin();
  const { hasUrlParams } = useQueryParams();
  const emailParam = hasUrlParams("email");
  const isFromPayment = hasUrlParams("payment-successful");
  const qrTokenParam = hasUrlParams("qrToken");

  const subline = isFromPayment
    ? "Your payment was successful. Log in to continue."
    : "Nice to see you again.";

  return (
    <div id="login_page">
      <Animate animation="fadeInLeft" delay=".15">
        <AuthenticationHeader subline={subline} />
      </Animate>
      <form onSubmit={handleSubmit(submitLogin)}>
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="email"
            type="email"
            label="Email"
            defaultValue={emailParam || undefined}
            placeholder="name@email.com"
            forwardRef={register({ required: true })}
            error={errors.email && "Email field is required"}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".35">
          <Input
            name="password"
            type="password"
            label="Password"
            placeholder="Something like &bull;&bull;&bull;&bull;&bull;&bull;"
            forwardRef={register({ required: true })}
            error={errors.password && "Password field is required"}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Button
            type="submit"
            name="login_button"
            value={isSubmitting ? "Logging in..." : "Log in"}
            fullWidth
            isLoading={isSubmitting}
          />
        </Animate>
      </form>
      <Animate animation="fadeInLeft" delay=".55">
        <AuthenticationFooter
          lineOne={<Link to="/forgot-password">Forgot password?</Link>}
          lineTwo={
            <>
              Don&apos;t have an account?{" "}
              <Link
                to={
                  qrTokenParam ? `/signup?qrToken=${qrTokenParam}` : "/signup"
                }
              >
                Sign Up
              </Link>
            </>
          }
        />
      </Animate>
    </div>
  );
};

export default LogIn;

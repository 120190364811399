import React, { useState } from "react";
import { DataTable, Modal, SidePanel } from "components";
import useGroupMembersDataColumns from "./utils/useGroupMembersDataColumns";
import { useViewport } from "utils/hooks";
import { User } from "types";
import GroupMembersDataCards from "../GroupMembersDataCards/GroupMembersDataCards";
import RemoveMemberConfirmationModal from "../RemoveMemberConfirmationModal/RemoveMemberConfirmationModal";
import SingleMember from "../SingleMember/SingleMember";

interface GroupMembersTableProps {
  groupId: string;
  data: User[];
}

const GroupMembersTable = ({ data, groupId }: GroupMembersTableProps) => {
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [showMemberPanel, setShowMemberPanel] = useState(false);
  const [currentMember, setCurrentMember] = useState<User | null>(null);

  const handleMemberClick = (currentMemer: User) => {
    setCurrentMember(currentMemer);
    setShowMemberPanel(true);
  };

  const handleMemberPanelClose = () => {
    setCurrentMember(null);
    setShowMemberPanel(false);
  };

  const handleOpenRemoveMemberModal = (currentMemer: User) => {
    setShowMemberPanel(false);
    setCurrentMember(currentMemer);
    setIsRemovalModalOpen(true);
  };

  const handleCloseRemoveMemberModal = () => {
    setCurrentMember(null);
    setIsRemovalModalOpen(false);
  };

  const groupMembersDataColumns = useGroupMembersDataColumns({
    dataLength: data.length,
    groupId,
    handleOpenRemoveMemberModal,
  });
  const { isDesktop } = useViewport();

  const rowProps = (row: any, rowRef: any) => {
    if (rowRef) {
      rowRef.onmouseover = () => {
        (member: User) => setCurrentMember(member);
      };
      rowRef.onclick = (event: React.MouseEvent<HTMLDivElement>) => {
        if ((event.target as HTMLElement).closest(".icon-button")) {
          return;
        }
        handleMemberClick(row.original);
      };
    }
  };

  return (
    <>
      {isDesktop ? (
        <DataTable
          data={data}
          columns={groupMembersDataColumns}
          dataType="groupMembers"
          id="group-members-table"
          rowProps={rowProps}
        />
      ) : (
        <GroupMembersDataCards
          groupMembers={data}
          groupId={groupId}
          onClick={(member: User) => handleMemberClick(member)}
          handleOpenRemoveMemberModal={handleOpenRemoveMemberModal}
        />
      )}
      <SidePanel
        title="Group member"
        isVisible={showMemberPanel}
        onClose={handleMemberPanelClose}
      >
        <SingleMember member={currentMember} />
      </SidePanel>
      <Modal isOpen={isRemovalModalOpen} onClose={handleCloseRemoveMemberModal}>
        <RemoveMemberConfirmationModal
          groupId={groupId}
          member={currentMember}
          onCancel={handleCloseRemoveMemberModal}
        />
      </Modal>
    </>
  );
};

export default GroupMembersTable;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { createCollectionAction } from "store/actions/collections";
import { useLogout, useCurrentUser } from "utils/hooks";

interface Resource {
  resourceUrl: string;
  title: string;
  description: string;
  imageUrl?: string;
  uuid?: string;
}
type createCollectionProps = {
  type: string;
  title: string;
  description?: string;
  defaultCollection?: boolean;
  collectionResources?: Array<Resource>;
};

const useCreateCollection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const createCollection = ({
    type,
    title,
    description,
    defaultCollection,
    collectionResources,
  }: createCollectionProps) => {
    setIsLoading(true);

    if (!title) {
      toast("Collection name is required");
      setIsLoading(false);
      return;
    }

    const collectionResourcesArray = collectionResources?.map((resource) => {
      return `{
        resourceUrl: "${resource.resourceUrl}",
        title: "${resource.title}",
        description: "${resource.description}",
        imageUrl: "${resource.imageUrl && resource.imageUrl}",
        uuid: "${resource.uuid && resource.uuid}"
      }`;
    });

    const resources = collectionResourcesArray ? collectionResourcesArray : [];

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              createCollectionByUserId(collection: {
                type: "${type}",
                title: "${title}",
                description: "${description ? description : ""}",
                defaultCollection: ${defaultCollection},
                collectionResources: [${resources}],
              }) {
                uuid
                userId
                type
                title
                description
                defaultCollection
                collectionResources {
                  uuid
                  resourceUrl
                  title
                  description
                  imageUrl
                  groupId
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else if (error.errors[0].message.includes("Title")) {
              toast.error(`Collection with this Title already exists`);
              setIsLoading(false);
              throw new Error("Failed!");
            } else {
              toast.error(`Hmm, something went wrong.`);
              setIsLoading(false);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
            setIsLoading(false);
          } else {
            ReactGA.event("collection_created_success", {
              user_id: user?.id,
              collection_id: resData.data.createCollectionByUserId.uuid,
            });
            return dispatch(
              createCollectionAction(resData.data.createCollectionByUserId)
            );
          }
        })
        .then((res) => {
          if (res) {
            toast("Collection created!");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    createCollection,
    isLoading,
  };
};

export default useCreateCollection;

import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import {
  addTherapistProfile,
  addTherapistPracticeInfo,
} from "store/actions/therapists";
import { toast } from "react-toastify";
import { useCurrentUser, useConfetti } from "utils/hooks";
import { useNavigate } from "react-router-dom";

const useTherapistOnboarding = ({ isFromSettings }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTherapistProfileSubmitted, setIsTherapistProfileSubmitted] =
    useState(false);
  const { isTherapist, user } = useCurrentUser();
  const { triggerConfetti } = useConfetti();

  const formatMultiSelectOptions = (selections) => {
    if (!selections) {
      return null;
    }
    return selections?.map((selection) => {
      return selection?.value;
    });
  };

  const createTherapistProfile = (args) => {
    if (!args || !isTherapist) {
      return null;
    }

    const hasAllFields =
      args.credentials && args.licenseNumber && args.licensingState;

    return new Promise((resolve) => {
      setTimeout(() => {
        setIsSubmitting(true);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                createTherapistProfile(therapistProfile: {
                  profileCredentials: "${formatMultiSelectOptions(
                    args.credentials
                  )}",
                  profileLicenseNumber: "${args.licenseNumber}",
                  profileLicensingState: "${args.licensingState?.value}",
                }) {
                  profileCredentials
                  profileLicenseNumber
                  profileLicensingState
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            orgId: user?.activeOrgId || "NA",
            permissionKey: "create_therapist_profiles",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              setIsSubmitting(false);
              toast.error(`Hmm, something went wrong.`);
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              setIsSubmitting(false);
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              if (resData.data.createTherapistProfile) {
                setIsSubmitting(false);
                setIsTherapistProfileSubmitted(true);
                if (hasAllFields) {
                  toast(`Your Therapist Profile is complete!`);
                  ReactGA.event("therapist_profile_submitted", {
                    user_id: user.id,
                  });
                }
                if (isFromSettings) {
                  toast(`Your Therapist Profile has been updated!`);
                }
                if (!isFromSettings) {
                  history("/onboarding/practice-info");
                }
              }
              return dispatch(
                addTherapistProfile(resData.data.createTherapistProfile)
              );
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            setIsTherapistProfileSubmitted(false);
            console.log(err);
          });
      }, 100);
    });
  };

  const createTherapistPracticeInfo = (args) => {
    if (!args || !isTherapist) {
      return null;
    }

    const hasAllFields =
      args.practiceName &&
      args.treatmentType &&
      args.practiceSize &&
      args.address1 &&
      args.state &&
      args.zipcode &&
      args.phone;

    return new Promise((resolve) => {
      setTimeout(() => {
        setIsSubmitting(true);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                createTherapistPracticeInfo(therapistPracticeInfo: {
                  practiceName: "${args.practiceName}",
                  treatmentType: "${formatMultiSelectOptions(
                    args.treatmentType
                  )}",
                  practiceSize: "${args.practiceSize?.value}",
                  address1: "${args.address1}",
                  address2: "${args.address2}",
                  state: "${args.state?.value}",
                  zipcode: "${args.zipcode}",
                  phone: "${args.phone}",
                }) {
                  practiceName
                  treatmentType
                  practiceSize
                  address1
                  address2
                  state
                  zipcode
                  phone
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            orgId: user?.activeOrgId || "NA",
            permissionKey: "create_organizations",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              setIsSubmitting(false);
              toast.error(`Hmm, something went wrong.`);
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              setIsSubmitting(false);
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              if (resData.data.createTherapistPracticeInfo) {
                setIsSubmitting(false);
                if (hasAllFields) {
                  toast(`Your Practice Information is complete!`);
                  ReactGA.event("therapist_practice_info_submitted", {
                    user_id: user.id,
                  });
                }
                if (isFromSettings) {
                  toast(`Your Practice Information has been updated!`);
                }
                if (!isFromSettings) {
                  triggerConfetti();
                  history("/dashboard");
                }
              }
              return dispatch(
                addTherapistPracticeInfo(
                  resData.data.createTherapistPracticeInfo
                )
              );
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    createTherapistPracticeInfo,
    createTherapistProfile,
    isSubmitting,
    isTherapistProfileSubmitted,
  };
};

export default useTherapistOnboarding;

import "react-datepicker/dist/react-datepicker.css";
import "./MultiValueField.styles.scss";
import React from "react";
import StringField from "../StringField";
import { Field, FieldOption } from "types";

interface MultiValueFieldProps extends Field {
  id?: string;
  readOnly?: boolean;
}

const MultiValueField = ({
  label,
  onChange,
  value,
  ...props
}: MultiValueFieldProps) => {
  // Safely parse props.config
  let config;
  try {
    config = props.config ? JSON.parse(props.config) : null;
  } catch (error) {
    console.error("Invalid JSON in config:", props.config, error);
    config = null; // Default to null if parsing fails
  }

  // Safely parse value
  let values: Record<string, string> = {};
  try {
    if (typeof value === "string" && value.trim().startsWith("{")) {
      values = JSON.parse(value);
    } else if (value && typeof value === "object") {
      values = value as unknown as Record<string, string>;
    } else {
      console.warn("Invalid or missing value for MultiValueField:", value);
      values = {}; // Default to an empty object if value is invalid
    }
  } catch (error) {
    console.error("Invalid JSON in value:", value, error);
    values = {}; // Default to an empty object if parsing fails
  }

  const fields = [];
  if (config && config.fields) {
    for (let i = 0; i < config.fields.length; i++) {
      const partField = config.fields[i];

      // Convert `values` object into `FieldOption[]`
      const options: FieldOption[] = Object.entries(values).map(
        ([key, val]) => ({
          label: key,
          value: val,
        })
      );

      fields.push(
        <StringField
          name={props.name + i}
          key={props.uuid + "-" + partField.name}
          placeholder={partField.placeholder}
          readOnly={props.readOnly}
          value={values[partField.name] || ""}
          onChange={(event) => {
            values[partField.name] = event.target.value;
            onChange && onChange(JSON.stringify(values));
          }}
          type="STRING"
          label=""
          status="VISIBLE"
          options={options} // Pass the transformed options here
          hint={props.hint !== "null" ? props.hint : ""}
          order={props.order}
          sectionId={props.sectionId}
          required={false}
        />
      );
    }
  }

  return (
    <div className="multi-value-field">
      <label className="multi-value-field__label">{label}</label>
      {fields}
    </div>
  );
};

export default MultiValueField;

import "./InfoBoxDisplay.styles.scss";
import React from "react";
import Emoji from "../Emoji";
import { IconAlignment } from "types";

type InfoBoxProps = {
  value: string | undefined;
  backgroundColor?: string;
  icon?: string;
  iconAlignment?: IconAlignment | string;
};

const InfoBoxDisplayField = ({
  value,
  backgroundColor = "#083D77", // Default to DARK_BLUE hex code
  icon = "💡",
  iconAlignment = "left",
}: InfoBoxProps) => {
  // Determine text color based on background brightness
  const isLightBackground = (color: string) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000; // Standard luminance formula
    return brightness > 150; // Adjust threshold as needed
  };

  const textColor = isLightBackground(backgroundColor) ? "black" : "white";

  return (
    <div
      className={`infobox_display_field align-${iconAlignment}`}
      style={{ backgroundColor, color: textColor }}
    >
      {iconAlignment === "top" && (
        <div className="icon">
          <Emoji emoji={icon} background="transparent" extraClass="condensed" />
        </div>
      )}
      <div className="content">
        {iconAlignment === "left" && (
          <div className="icon">
            <Emoji
              emoji={icon}
              background="transparent"
              extraClass="condensed"
            />
          </div>
        )}
        {value ? (
          <div dangerouslySetInnerHTML={{ __html: value ? value : "" }}></div>
        ) : (
          <div>
            &quot;Who looks outside dreams; who looks inside awakes&quot; - Carl
            Jung
          </div>
        )}
        {iconAlignment === "right" && (
          <div className="icon">
            <Emoji
              emoji={icon}
              background="transparent"
              extraClass="condensed"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBoxDisplayField;

import React from "react";
import { IconButton } from "components";
import "./GroupMemberButtons.styles.scss";
import { useCurrentUser, useResendGroupInvite } from "utils/hooks";
import { User } from "types";

interface GroupMemberButtonsProps {
  member: User;
  groupId: string;
  handleOpenRemoveMemberModal: (currentMember: User) => void;
}

const GroupMemberButtons = ({
  member,
  groupId,
  handleOpenRemoveMemberModal,
}: GroupMemberButtonsProps) => {
  const { user } = useCurrentUser();
  const { resendGroupInvite } = useResendGroupInvite();

  const showResendInviteBtn =
    member.status === "pending" && user?.id === member.invitingTherapist;

  const showRemoveBtn = user?.id === member.invitingTherapist;

  return (
    <>
      <div className="group-member-buttons">
        {showRemoveBtn && (
          <IconButton
            value="Remove"
            icon="Remove"
            name="group-member_remove_btn"
            extraClass="group-member-buttons_btn"
            onClick={() => handleOpenRemoveMemberModal(member)}
          />
        )}
        {showResendInviteBtn && (
          <IconButton
            value="Resend Invite"
            icon="Envelope"
            name="group-member_resend_invite_btn"
            extraClass="group-member-buttons_btn"
            onClick={() =>
              resendGroupInvite({
                member,
                groupId,
              })
            }
          />
        )}
      </div>
    </>
  );
};

export default GroupMemberButtons;

import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import { verifyAuthenticatedUser } from "store/actions/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useVerifyVerificationCode = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user, isTherapist, isSubscribed } = useCurrentUser();
  const history = useNavigate();

  const verifyVeriricationCode = useCallback(
    async (args: { code: string }) => {
      const { code } = args;
      const timezoneOffset = new Date().getTimezoneOffset();

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                verifyUserCode(email: "${user?.email}", code: "${code}")
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-User-Timezone-Offset": timezoneOffset.toString(),
            orgId: user?.activeOrgId || "NA",
            permissionKey: "verify_account",
          },
        });

        const resData = await response.json();

        if (resData.data.verifyUserCode) {
          ReactGA.event("account_verification_success", {
            user_id: user?.id,
            user_type: user?.type,
            user_verified: user?.verified,
          });

          toast("Your account is now verified.");
          dispatch(verifyAuthenticatedUser());
          let destination = "/submissions";
          if (isTherapist && isSubscribed) {
            destination = "/dashboard";
          }
          if (isTherapist && !isSubscribed) {
            destination = "/please-wait";
          }

          if (!isTherapist) {
            destination = "/your-assignments";
          }
          return history(destination);
        } else {
          if (
            resData.errors &&
            resData.errors[0].message.includes("BRKN_6001")
          ) {
            toast.warn("For security purposes please log in again.");
            logOut();
          } else {
            toast.error("Verification failed.");
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while verifying the code.");
      }
    },
    [
      dispatch,
      history,
      isSubscribed,
      isTherapist,
      logOut,
      user?.activeOrgId,
      user?.email,
      user?.id,
      user?.type,
      user?.verified,
    ]
  );

  return {
    verifyVeriricationCode,
  };
};

export default useVerifyVerificationCode;

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import {
  fetchCollectionsAction,
  fetchPatientCollectionsAction,
} from "store/actions/collections";
import { useLogout, useCurrentUser } from "utils/hooks";

const useFetchCollections = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const fetchCollectionsByUser = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getCollectionsByUserId {
                uuid
                userId
                type
                title
                description
                defaultCollection
                collectionResources {
                  uuid
                  resourceUrl
                  title
                  description
                  imageUrl
                  groupId
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            ReactGA.event("fetch_collections_success", {
              user_id: user?.id,
              collections: resData.data.getCollectionsByUserId,
            });
            return dispatch(
              fetchCollectionsAction(resData.data.getCollectionsByUserId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchCollectionsForTherByPatientId = (patientId: string) => {
    if (!patientId) {
      return;
    }

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getCollectionsForTherapistByPatientId(patientId: "${patientId}") {
                uuid
                userId
                type
                title
                description
                defaultCollection
                collectionResources {
                  uuid
                  resourceUrl
                  title
                  description
                  imageUrl
                  groupId
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            ReactGA.event("fetch_patient_collections_success", {
              user_id: user?.id,
              collections: resData.data.getCollectionsForTherapistByPatientId,
            });
            return dispatch(
              fetchPatientCollectionsAction(
                resData.data.getCollectionsForTherapistByPatientId
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    fetchCollectionsByUser,
    fetchCollectionsForTherByPatientId,
  };
};

export default useFetchCollections;

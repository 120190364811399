import React from "react";
import { useTranslation } from "react-i18next";
import { useGetGroups } from "utils/hooks";
import usePatientSubmissionsTabs from "./utils/usePatientSubmissionsTabs";
import { Tabs, TopNav, PageTitle } from "components";
import PatientsSubmissionsTable from "./components/PatientSubmissionsTable/PatientsSubmissionsTable";

const SubmissionsPage = () => {
  const { t } = useTranslation(["common"]);
  const { groups } = useGetGroups({});
  const { patientSubmissionsTabs, activeTab, handleTabChange } =
    usePatientSubmissionsTabs(groups);

  const hasGroups = groups && groups.length > 0;

  return (
    <>
      <TopNav
        topL={
          <div className="flex align-center">
            <PageTitle text={t("patient_assignments.your_submission_label")} />
          </div>
        }
        botL={
          hasGroups ? (
            <Tabs
              items={patientSubmissionsTabs}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              size="large"
              placement="left"
            />
          ) : null
        }
      />
      {hasGroups ? (
        <div>{activeTab?.mainComponent}</div>
      ) : (
        <PatientsSubmissionsTable />
      )}
    </>
  );
};

export default SubmissionsPage;

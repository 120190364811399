import "./AssignmentsForPatientsDesktopFooter.styles.scss";
import React from "react";
import { FlatButton } from "components";
import { useTranslation } from "react-i18next";
import PatientSubmissionPrivacySetting from "../PatientSubmissionPrivacySetting";
import PatientSubmissionGroupPrivacySetting from "../PatientSubmissionGroupPrivacySetting";
interface Props {
  isSubmitting: boolean;
  isFinalSection: boolean;
  onPrivacyChange: (value: boolean) => void;
  isPrivate: boolean;
  isInfosheet: boolean;
  isGroupPrivate: boolean;
  onGroupPrivacyChange: (value: boolean) => void;
  isGroup: boolean;
}
const AssignmentsForPatientsDesktopFooter = ({
  isSubmitting,
  isFinalSection,
  onPrivacyChange,
  isPrivate,
  isInfosheet,
  isGroupPrivate,
  onGroupPrivacyChange,
  isGroup,
}: Props) => {
  const { t } = useTranslation(["common"]);
  if (isInfosheet) {
    return <></>;
  }
  return (
    <div className="assignments-for-patients-desktop-footer">
      <div className="assignments-for-patients-desktop-footer__checkboxes">
        {isGroup && (
          <PatientSubmissionGroupPrivacySetting
            onGroupPrivacySettingChange={onGroupPrivacyChange}
            isGroupPrivate={isGroupPrivate}
          />
        )}
        <PatientSubmissionPrivacySetting
          onPrivacySettingChange={onPrivacyChange}
          isPrivate={isPrivate}
          isDisabled={isGroup && !isGroupPrivate}
        />
      </div>

      <FlatButton disabled={isSubmitting} isLoading={isSubmitting}>
        {isFinalSection
          ? t("common_labels.submit_button")
          : t("common_labels.save_and_continue_button")}
      </FlatButton>
    </div>
  );
};

export default AssignmentsForPatientsDesktopFooter;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProfileSettings from "./components/ProfileSettings";
import TherapistProfileSettings from "./components/TherapistProfileSettings";
import InviteCode from "./components/InviteCode";
import PatientReminderSettings from "./components/PatientReminderSettings";
import SubscriptionSettings from "./components/SubscriptionSettings/SubscriptionSettings";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "utils/hooks";
import { TabItem } from "components/elements/Tabs/Tabs";

const useSettingsPageTabs = () => {
  const { user, isTherapist, isPatient } = useCurrentUser();
  const { t } = useTranslation(["common"]);
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  const tabItems: TabItem[] = [
    {
      name: t("settings.profile_tab_label"),
      key: "account",
      mainComponent: <ProfileSettings user={user} />,
      url: "account",
    },
    ...(isPatient
      ? [
          {
            name: t("settings.reminders_tab_label"),
            key: "reminders",
            mainComponent: <PatientReminderSettings />,
            url: "reminders",
          },
        ]
      : []),
    ...(isTherapist
      ? [
          {
            name: t("settings.therapist_profile_tab_label"),
            key: "therapistProfile",
            mainComponent: <TherapistProfileSettings />,
            url: "therapist-profile",
          },
          {
            name: t("settings.invite_code_tab_label"),
            key: "inviteCode",
            mainComponent: <InviteCode />,
            url: "invite-code",
          },
          {
            name: t("settings.subscription_tab_label"),
            key: "subscription",
            mainComponent: <SubscriptionSettings user={user} />,
            url: "subscription",
          },
        ]
      : []),
  ];

  const getTabFromUrl = (): TabItem | undefined => {
    return tabItems.find((tab) => tab.url === slug);
  };

  const defaultTab = getTabFromUrl() || tabItems[0];

  const [activeTab, setActiveTab] = useState<TabItem>(defaultTab);

  const handleTabChange = (tab: TabItem) => {
    navigate(`/settings/${tab.url}`);
    setActiveTab(tab);
  };

  useEffect(() => {
    handleTabChange(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (activeTab) {
      navigate(`/settings/${activeTab.url}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tabItems,
    activeTab,
    handleTabChange,
  };
};

export default useSettingsPageTabs;

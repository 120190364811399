import { Collection } from "types";

export const fetchCollectionsAction = (collections: Array<Collection>) => {
  return {
    type: "FETCH_COLLECTIONS",
    payload: collections,
  };
};

export const fetchPatientCollectionsAction = (
  collections: Array<Collection>
) => {
  return {
    type: "FETCH_PATIENT_COLLECTIONS",
    payload: collections,
  };
};

export const createCollectionAction = (collection: Collection) => {
  return {
    type: "CREATE_COLLECTION",
    payload: collection,
  };
};

export const deleteCollectionAction = (collectionId: string) => {
  return {
    type: "DELETE_COLLECTION",
    payload: collectionId,
  };
};

export const updateCollectionAction = (collection: Collection) => {
  return {
    type: "UPDATE_COLLECTION",
    payload: collection,
  };
};

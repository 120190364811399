import React, { useMemo } from "react";
import { pluralize } from "utils/helpers";
import { useCurrentUser } from "utils/hooks";
import GroupMemberButtons from "../../GroupMemberButtons/GroupMemberButtons";
import { User } from "types";
interface useGroupMembersDataColumnsProps {
  dataLength: number;
  groupId: string;
  handleOpenRemoveMemberModal: (currentMember: User) => void;
}

const useGroupMembersDataColumns = ({
  dataLength,
  groupId,
  handleOpenRemoveMemberModal,
}: useGroupMembersDataColumnsProps) => {
  const { user } = useCurrentUser();
  const groupMembersDataColumns = useMemo(
    () => [
      {
        header: "First name",
        accessorKey: "firstName",
        footer: `${dataLength} ${pluralize(dataLength, "Member")}`,
        cell: (props: any) => (
          <span className="carolina-blue-text underlined">
            {props.row.original.firstName}
          </span>
        ),
      },
      {
        header: "Last name",
        accessorKey: "lastName",
        footer: "",
        cell: (props: any) => (
          <span className="bold">{props.row.original.lastName}</span>
        ),
      },
      {
        header: "Email",
        accessorKey: "email",
        footer: "",
        cell: (props: any) => (
          <span className="carolina-blue-text underlined">
            {props.row.original.email}
          </span>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        footer: "",
        cell: (props: any) => {
          const { status } = props.row.original;
          return (
            <>
              {status === "pending" ? (
                <span className="italic">{status}...</span>
              ) : (
                <span>{status}</span>
              )}
            </>
          );
        },
      },
      {
        header: "Role",
        accessorKey: "role",
        footer: "",
        cell: (props: any) => {
          const { role, email } = props.row.original;
          const isCurrentTherapist = email === user?.email;
          return (
            <>
              <span className={`${role !== "member" ? "bold" : ""}`}>
                {role}
              </span>
              {isCurrentTherapist && <span> (You)</span>}
            </>
          );
        },
      },
      {
        header: "",
        id: "group-member-buttons",
        enableSorting: false,
        footer: "",
        cell: (props: any) => (
          <GroupMemberButtons
            member={props.row.original}
            groupId={groupId}
            handleOpenRemoveMemberModal={handleOpenRemoveMemberModal}
          />
        ),
      },
    ],
    [dataLength, user?.email, groupId, handleOpenRemoveMemberModal]
  );

  return groupMembersDataColumns;
};

export default useGroupMembersDataColumns;

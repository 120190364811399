import { RootStateOrAny, useSelector } from "react-redux";

const useGetCollections = () => {
  const collectionsState = useSelector(
    (state: RootStateOrAny) => state.collections && state.collections
  );

  return {
    collections: collectionsState.collections,
    patientCollections: collectionsState.patientCollections,
  };
};

export default useGetCollections;
